<template>
  <v-container
    fluid
    class="margin-bottom-footer-tab-three"
  >
    <v-row>
      <v-col>
        <div
          v-if="isResponsive"
          class="width-100 display-flex align-items-center justify-content-flex-end"
        >
          <v-btn
            @click="dialogProfileAddDocument"
            class="button-primary mon-bold"
            elevation="0"
            width="40px !important"
            height="40px !important"
            min-width="40px !important"
          >
            <v-icon size="18px">mdi-plus-thick</v-icon>
          </v-btn>
        </div>
        <div
          v-else
          class="width-100 display-flex align-items-center justify-content-flex-end"
        >
          <v-btn
            @click="dialogProfileAddDocument"
            class="button-yellow mon-bold"
            elevation="0"
          >
            {{ texts.userprofile.attachments.buttonAddDocument }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="documents.length > 0">
      <v-col>
        <div v-if="isResponsive">
          <div
            v-for="(item, index) in documents"
            :key="index"
            class="card-content mb-5"
            style="height: auto; padding: 20px 10px;"
          >
            <div class="display-flex align-items-flex-start mb-2">
              <p class="mon-bold mb-0">{{ texts.userprofile.attachments.table.headers[0] }}</p>
              <v-spacer />
              <p
                style="width: 100%; overflow-wrap: break-wrap; text-align: right;"
                class="mon-regular text-right mb-0 ml-2"
              >{{ item.sVendorFileTypeName }}</p>
            </div>
            <div class="display-flex align-items-flex-start">
              <p class="mon-bold mb-0">
                {{
                  (selectLanguage == 'sp')
                  ? 'Documentos'
                  : 'Documents'
                }}
              </p>
              <v-spacer />
              <p
                style="color: #0971fb; width: 100%; overflow-wrap: break-wrap; text-align: right;"
                class="word-break mon-regular text-right text-link mb-0 ml-2"
                @click="openDocument(item.sFileUrl)"
              >
                {{ item.sVendorFileName }}
              </p>
            </div>
            <v-btn
              @click="deleteDocument(item.sVendorFileTypeId)"
              class="button-tertiary mt-4"
              style="width: 100%;"
              elevation="0"
            >
              {{
                (selectLanguage == 'sp')
                ? 'Eliminar'
                : 'Delete'
              }}
            </v-btn>
          </div>
        </div>
        <div
          v-else
          class="width-100"
        >
          <div class="card-content">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="(item, index) in texts.userprofile.attachments.table.headers"
                      :key="index"
                      class="text-left text-header-title mon-bold"
                    >
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in documents"
                    :key="index"
                  >
                    <td class="mon-regular border-bottom">{{ item.sVendorFileTypeName }}</td>
                    <td
                      class="mon-regular text-link border-bottom"
                      @click="openDocument(item.sFileUrl)"
                    >
                      {{ item.sVendorFileName }}
                    </td>
                    <td class="mon-regular border-bottom">
                      <v-btn
                        @click="deleteDocument(item.sVendorFileTypeId)"
                        icon
                      >
                        <v-icon
                          color="#D8686A"
                          size="18px"
                        >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <div
          style="height: 500px;"
          class="card-content display-flex align-items-center justify-content-center"
        >
          <p class="empty-text mon-regular">{{ texts.userprofile.textEmpty }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UserProfileContentTabThree",
  props: {
    texts: {
      type: Object,
    },
    tab: {
      type: Number,
    },
  },
  data() {
    return {
      //VARIABLES
      documents: [],
      isResponsive: false,
    };
  },
  beforeMount() {
    this.getFiles();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    openDocument: function (url) {
      window.open(url, "_blank");
    },
    dialogProfileAddDocument: function () {
      this.$store.commit("setProfileDialogAddDocument", true);
    },
    deleteDocument: function (typeFileId) {
      this.$store.commit("setDialogDelete", {
        active: true,
        title: this.texts.userprofile.attachments.textTitleDialogDelete,
        api: `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/files/${typeFileId}`,
        redirect: "",
      });
    },
    getFiles: function () {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.$store.state.sEnterpriseId}/files`,
        config
      )
        .then((response) => {
          this.documents = response.data.results;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    refresh() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts.userprofile[this.selectLanguage];
      }
    },
    refresh: function () {
      this.getFiles();
    },
    tab: function () {
      if (this.tab == 2) {
        this.getFiles();
      }
    },
  },
};
</script>

<style scoped>
.margin-bottom-footer-tab-three {
  margin-bottom: 90px;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.button-yellow {
  background: transparent linear-gradient(254deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 13px;
  opacity: 1;
  font-size: 14px;
  height: 40px !important;
  padding-left: 25px;
  padding-right: 25px;
  text-transform: initial;
}

.text-link {
  color: #0971fb;
  text-decoration: underline;
}

.text-link:hover {
  cursor: pointer;
}

.text-header-title {
  color: #000000 !important;
  font-size: 14px !important;
  border-bottom: 1px solid #949494 !important;
}

.card-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  opacity: 1;
  padding: 10px;
  height: 250px;
}

.content-tab {
  padding: 20px 0px;
}

.border-top-line {
  border-top: 1px solid #cecece;
}

.border-bottom-line {
  border-bottom: 1px solid #cecece;
}

@media (max-width: 350px) {
  .flex-wrap p {
    font-size: 14px !important;
    text-align: left !important;
  }
}

.word-break {
  width: 230px !important;
  word-break: break-all;
}
</style>